<template>
	<CHeader position="sticky" class="mb-4">
        <CContainer fluid>
			<CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
				<CIcon icon="cil-menu" />
			</CHeaderToggler>
			<CHeaderBrand class="mx-auto d-lg-none" to="/">
				<!-- <CIcon :icon="logo" height="48" alt="Logo1" /> -->
				<img class="sidebar-brand-full" alt="Famewurk Logo" width="200" height="46" :src="require('@/assets/logo.png')" />
			</CHeaderBrand>
			<CHeaderNav class="d-none d-md-flex me-auto">
				<CNavItem>
					<router-link :to="{ name: 'Dashboard' }"> Dashboard </router-link>
				</CNavItem>
			</CHeaderNav>
			<CHeaderNav>
				<AdminHeaderDropdownAccnt />
			</CHeaderNav>
		</CContainer>
		<CHeaderDivider />
		<CContainer fluid class="justify-content-md-end">
			<AdminBreadcrumb />
		</CContainer>
	</CHeader>
</template>

<script>
	import AdminBreadcrumb from './AdminBreadcrumb'
	import AdminHeaderDropdownAccnt from './AdminHeaderDropdownAccnt'
	import { logo } from '@/assets/brand/logo'
	export default {
		name: 'AppHeader',
		components: {
			AdminBreadcrumb,
			AdminHeaderDropdownAccnt,
		},
		setup() {
			return {
				logo,
			}
		}
	}
</script>
