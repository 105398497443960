<template>
<div>
    <div class="loader-wrapper" id="loader-wrapper" style="display: none;">
        <div id="loading-bar-spinner" class="spinner">
            <div class="spinner-icon"></div>
        </div>
    </div>
    <AdminSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
        <adminHeader />
        <div class="body flex-grow-1 px-3">
            <!-- <CContainer lg> -->
            <CContainer fluid>
                <slot></slot>
            </CContainer>
        </div>
        <adminFooter />
    </div>
</div>
</template>

<script>
import {
    CContainer
} from '@coreui/vue'
import AdminFooter from '@/components/AdminFooter.vue'
import AdminHeader from '@/components/AdminHeader.vue'
import AdminSidebar from '@/components/AdminSideBar.vue'

export default {
    name: 'AdminLayout',
    components: {
        AdminFooter,
        AdminHeader,
        AdminSidebar,
        CContainer,
    },
}
</script>

<style lang="css">
.sidebar.sidebar-fixed {
    background-color: #122355;
}

/* .sidebar-nav .nav-link.active {
    background: #D4A2A0 !important;
    background: linear-gradient(90deg, #1E075E 0%, #390671 22.87%, #550669 48.00%, #710761 74.52%, #AD0344 100%) !important;
    background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(49, 0, 253, 0.80) 0%, rgba(132, 11, 223, 0.00) 100%);
}  */

.sidebar-nav .nav-link:hover {
    /* background: #5A461C !important; 
	border-radius: 30px;*/
    /* background: radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 77.08%, rgba(255, 255, 255, 0.00) 100%) !important; 
	backdrop-filter: blur(5.654404640197754px) !important;  */

    background: radial-gradient(151.92% 127.02% at 15.32% 21.04%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.04) 77.08%, rgba(255, 255, 255, 0.00) 100%);

}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border-top: 4px solid #007bff; /* Change color here */
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
