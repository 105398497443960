import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AdminLayout from '@/layouts/AdminLayout.vue'
import store from '../store'
import axios from 'axios'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/HomePage'),
		meta: { layout: DefaultLayout },
		requiresAdminAuth: false
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: () => import('@/views/user/privacy-policy'),
		requiresAdminAuth: false,
	},
	{
		path: '/license',
		name: 'LicensePage',
		component: () => import('@/views/user/license'),
		requiresAdminAuth: false,
	},
	{
		path: '/terms',
		name: 'TermsCondition',
		component: () => import('@/views/user/terms-condition'),
		requiresAdminAuth: false,
	},
	{
		path: '/password/reset/:token',
		name: 'UserResetPassword',
		component: () => import('@/views/user/auth/ResetPassword'),
		requiresAdminAuth: false
	},	
	{
		path: '/delete-account',
		name: 'VerifyDeleteAccount',
		component: () => import('@/views/user/VerifyAccountPage'),
		requiresAdminAuth: false,
	},
	{
		path: '/deeplink',
		name: 'DeeplinkPage',
		component: () => import('@/views/deeplink'),
		requiresAdminAuth: false,
	},
	{
		path: '/admin',
		redirect: '/admin/login',
		name: 'Admin',
		meta: { layout: AdminLayout },
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/views/admin/auth/Login'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset',
				name: 'ForgotPassword',
				component: () => import('@/views/admin/auth/ForgotPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'password/reset/:token',
				name: 'ResetPassword',
				component: () => import('@/views/admin/auth/ResetPassword'),
				requiresAdminAuth: false,
			},
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Famewurk',
					metaTags: [{name: 'description',content: 'The dashboard page of our Famewurk.'},{property: 'og:description',content: 'The dashboard page of our Famewurk.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: '403',
				name: '403',
				component: () => import('@/views/admin/AdminDashboard'),
				meta: {
					title: 'Dashboard - Famewurk',
					metaTags: [{name: 'description',content: 'The dashboard page of our Famewurk.'},{property: 'og:description',content: 'The dashboard page of our Famewurk.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'profile-update',
				name: 'Profile Update',
				component: () => import('@/views/admin/ProfileUpdate'),
				meta: {
					title: 'Dashboard - profile-update',
					metaTags: [{name: 'description',content: 'The login user profile update page of our Famewurk.'},{property: 'og:description',content: 'The dashboard page of our Famewurk.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: 'change-password',
				name: 'Change Password',
				component: () => import('@/views/admin/ChangePassword'),
				meta: {
					title: 'Dashboard - Famewurk',
					metaTags: [{name: 'description',content: 'The dashboard page of our Famewurk.'},{property: 'og:description',content: 'The dashboard page of our Famewurk.'}],
					requiresAdminAuth: true,
				}
			},
			{
				path: "user",
				name: "User",
				redirect: '/admin/user/list',
				children: [
					{
						path: "list",
						name: "User List",
						component: () => import('@/views/admin/user/List'),
						meta: {
							title: 'User Management - Famewurk',
							metaTags: [{name: 'description',content: 'The user Management page of our Famewurk.'},{property: 'og:description',content: 'The user Management page of our Famewurk.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: "create",
						name: "User Create",
						component: () => import('@/views/admin/user/Create'),
						meta: {
							title: 'User Management - Famewurk',
							metaTags: [{name: 'description',content: 'The user Management page of our Famewurk.'},{property: 'og:description',content: 'The user Management page of our Famewurk.'}],
							requiresAdminAuth: true,
						}
					},
					{
						path: ":id/edit",
						name: "User Edit",
						component: () => import('@/views/admin/user/Edit'),
						meta: {
							title: 'User Management - Famewurk',
							metaTags: [{name: 'description',content: 'The User Management page of our Famewurk.'},{property: 'og:description',content: 'The User Management page of our Famewurk.'}],
							requiresAdminAuth: true,
						}
					},
				],
			},
		],
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
  })

router.beforeEach(async (to, from, next) => {
	// Set the title
	document.title = to.meta.title || 'Famewurk' // Default title if not set

	// Set the meta tags
	const metaTags = to.meta.metaTags
	if (metaTags) {
		metaTags.forEach(tag => {
			const { name, content } = tag
			const metaTag = document.createElement('meta')
			metaTag.setAttribute(name, content)
			document.head.appendChild(metaTag)
		});
	}

	// Determine the layout based on the meta property of the route
	const layout = to.meta.layout || 'DefaultLayout'
	// Update the layout component dynamically based on the layout value
	if (layout === 'DefaultLayout' || layout === 'AdminLayout') {
        // Check if the Vue instance is available
        if (router.app) {
            router.app.$options.components.Layout = layout === 'AdminLayout' ? AdminLayout : DefaultLayout;
        } else {
            console.error('Vue instance not available.');
        }
    }

	const isAdminLoggedIn = store.getters.isLoggedIn; // Implement the isLoggedIn getter in your store

	if (to.matched.some(route => route.meta.requiresAdminAuth) && isAdminLoggedIn === false) {
		// If the route requires authentication and the user is not logged in
		next('/admin');
	} else {
		if(to.path == '/admin/login' && isAdminLoggedIn === true){
			next('/admin/dashboard');
		}
	}

	if(isAdminLoggedIn === true && to.name != "Dashboard"){
		try {
			// Check if the token is valid
			const response = await axios.post('/check-auth-token');
			if (response.data.success) {
				// Token is valid, proceed to the route
				next();
			} else {
				store.dispatch('logout');

				// Redirect to login if token is invalid
				next('/admin/login');
			}
		} catch (error) {
			store.dispatch('logout');
			// Redirect to login if there's an error in token verification
			next('/admin/login');
		}
	} else {
		next();
	}

	next();
})

export default router
