<template>
	<CSidebar
		position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible"
		@visible-change="(event) => $store.commit({type: 'updateSidebarVisible',value: event})">

		<CSidebarBrand>
			<img class="sidebar-brand-full" alt="Famewurk Logo" width="200" :src="require('@/assets/logo.png')" />
			<img class="sidebar-brand-narrow" width="63" :src="require('@/assets/logo.png')" />
		</CSidebarBrand>

		<CSidebarNav>
			<CNavItem>
				<router-link class="nav-link" :to="{ name: 'Dashboard' }" exact exact-active-class="active"><CIcon  customClassName="nav-icon" icon="cil-speedometer"/> Dashboard</router-link>
			</CNavItem>
			<!-- User Management -->
			<CNavItem>
				<router-link :class="{ active: isRouteActive(['User List','User Create','User Edit']) }" class="nav-link" :to="{ name: 'User List' }"><CIcon  customClassName="nav-icon" icon="cil-user"/> User Management</router-link>
			</CNavItem>
		</CSidebarNav>
		<CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')"/>
	</CSidebar>
</template>
  
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
	name: 'AppSidebar',
	data() {
		return {
            isDeveloper:false
		}
	},
	setup() {
		const store = useStore()
		return {
			logoNegative,
			sygnet,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
		}
	},
	methods: {
		isRouteActive(routeName) {
			return routeName.includes(this.$route.name);
		}
	}
}
</script>

<style scoped>
	.sidebar-fixed {width: 14.3% !important;}
	.sidebar-brand { margin-bottom: 10px;}
	@media screen and (max-width:1599px) {
		.sidebar.sidebar-fixed .sidebar-nav .nav-link {font-size: 15px !important;}
	}
</style>
