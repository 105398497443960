import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import Vuex from "vuex";
import axios from 'axios';
import { createMetaManager } from 'vue-meta'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";

import ToastPlugin from 'vue-toast-notification';
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
// import DocsExample from '@/components/DocsExample'
import '@coreui/coreui/dist/css/coreui.min.css'

import ActionsRenderer from "./components/AdminActionsRenderer.vue"; // Import the ActionsRenderer component
import "@coreui/icons/css/all.min.css"; // Import the CSS file that contains the icon styles

import './assets/css/tailwind.css';
import { BASE_URL } from '@/urlConstants';

axios.defaults.baseURL = BASE_URL + '/api/admin/';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Accept'] = 'application/json';
const isAdminLoggedIn = store.getters.isLoggedIn; // Implement the isLoggedIn getter in your store
// console.log("isAdminLoggedIn",isAdminLoggedIn);
if (isAdminLoggedIn) {
    const token = store.getters.currentUser.token; 
    axios.defaults.headers.common["Authorization"] = "Bearer "+token;
}

const app = createApp(App)
app.use(store)
app.use(Vuex)
app.use(router)
app.use(CoreuiVue,{
    iconSet: icons
})
app.provide('icons', icons)
app.component('CIcon', CIcon)
// app.component('DocsExample', DocsExample)
app.component("actionsRenderer", ActionsRenderer); // Register the ActionsRenderer component globally
const metaManager = createMetaManager()
app.use(metaManager)
app.use(ToastPlugin)
app.mount('#app')