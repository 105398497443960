<template>
	<div>
		<header class="text-gray-600 body-font justify-center bg-cover bg-center" :style="{ backgroundImage: `url(${backgroundImageUrl})` }">
			<div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
				<a class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
					<img src="../assets/logo.png" class=""/>
				</a>
				<nav class="md:ml-auto flex flex-wrap items-center text-white text-base justify-center">
					<a class="mr-5 hover:text-gray-900">Home</a>
					<a class="mr-5 hover:text-gray-900">Second Link</a>
					<a class="mr-5 hover:text-gray-900">Third Link</a>
					<a class="mr-5 hover:text-gray-900">Fourth Link</a>
				</nav>
				<!-- <button class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Login
					<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
						<path d="M5 12h14M12 5l7 7-7 7"></path>
					</svg>
				</button> -->
			</div>
		</header>
		<main>
			<slot></slot>
		</main>
		<footer class="text-gray-600 body-font">
			<div class="container px-5 py-24 mx-auto">
				<div class="flex flex-wrap md:text-left text-center -mb-10 -mx-4">
					<div class="lg:w-1/6 md:w-1/2 sm:w-1/2 w-full px-4">
						<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
						<nav class="list-none mb-10">
							<li>
								<a class="text-gray-600 hover:text-gray-800">First Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Second Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Third Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
							</li>
						</nav>
					</div>
					<div class="lg:w-1/6 md:w-1/2 sm:w-1/2 w-full px-4">
						<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
						<nav class="list-none mb-10">
							<li>
								<a class="text-gray-600 hover:text-gray-800">First Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Second Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Third Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
							</li>
						</nav>
					</div>
					<div class="lg:w-1/6 md:w-1/2 sm:w-1/2 w-full px-4">
						<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
						<nav class="list-none mb-10">
							<li>
								<a class="text-gray-600 hover:text-gray-800">First Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Second Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Third Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
							</li>
						</nav>
					</div>
					<div class="lg:w-1/6 md:w-1/2 sm:w-1/2 w-full px-4">
						<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
						<nav class="list-none mb-10">
							<li>
								<a class="text-gray-600 hover:text-gray-800">First Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Second Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Third Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
							</li>
						</nav>
					</div>
					<div class="lg:w-1/6 md:w-1/2 sm:w-1/2 w-full px-4">
						<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
						<nav class="list-none mb-10">
							<li>
								<a class="text-gray-600 hover:text-gray-800">First Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Second Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Third Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
							</li>
						</nav>
					</div>
					<div class="lg:w-1/6 md:w-1/2 sm:w-1/2 w-full px-4">
						<h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">CATEGORIES</h2>
						<nav class="list-none mb-10">
							<li>
								<a class="text-gray-600 hover:text-gray-800">First Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Second Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Third Link</a>
							</li>
							<li>
								<a class="text-gray-600 hover:text-gray-800">Fourth Link</a>
							</li>
						</nav>
					</div>
				</div>
			</div>
			<div class="border-t border-gray-200">
				<div class="container px-5 py-8 flex flex-wrap mx-auto items-center">
					<div class="flex md:flex-nowrap flex-wrap justify-center items-end md:justify-start">
						<div class="relative sm:w-64 w-40 sm:mr-4 mr-2">
							<label for="footer-field" class="leading-7 text-sm text-gray-600">Placeholder</label>
							<input type="text" id="footer-field" name="footer-field" class="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:ring-2 focus:bg-transparent focus:ring-indigo-200 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
						</div>
						<button class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">Button</button>
						<p class="text-gray-500 text-sm md:ml-6 md:mt-0 mt-2 sm:text-left text-center">Bitters chicharrones fanny pack
							<br class="lg:block hidden">waistcoat green juice
						</p>
					</div>
					<span class="inline-flex lg:ml-auto lg:mt-0 mt-6 w-full justify-center md:justify-start md:w-auto">
						<a class="text-gray-500">
							<svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
								<path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
							</svg>
						</a>
						<a class="ml-3 text-gray-500">
							<svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
								<path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
							</svg>
						</a>
						<a class="ml-3 text-gray-500">
							<svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
								<rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
								<path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
							</svg>
						</a>
						<a class="ml-3 text-gray-500">
							<svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
								<path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
								<circle cx="4" cy="4" r="2" stroke="none"></circle>
							</svg>
						</a>
					</span>
				</div>
			</div>
			<div class="bg-gray-100">
				<div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
					<p class="text-gray-500 text-sm text-center sm:text-left">© 2020 Tailblocks —
						<a href="https://twitter.com/knyttneve" class="text-gray-600 ml-1" target="_blank" rel="noopener noreferrer">@knyttneve</a>
					</p>
					<span class="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">Enamel pin tousled raclette tacos irony</span>
				</div>
			</div>
		</footer>
	</div>
</template>
  
<script>
// Import the image or define its URL
import backgroundImageUrl from '@/assets/right-nav.png'; // Adjust the import path as needed

export default {
	name: 'DefaultLayout',
	data() {
    return {
      backgroundImageUrl,
    };
  },
};
</script>
  
<style scoped>
/* Add your default layout styles here */
.mt-4 {
    margin-top: 0rem !important;
}
.p-5 {
    padding: 1rem !important;
}
</style>